<template>
  <div class="help-containter">
    <nav-menu></nav-menu>
    <div class="help-body">
      <div class="containter flex">
        <div class="help-left">
          <div :class="tab == 'help' ? 'active' : ''" @click="changeTab('help')">帮助中心</div>
          <div v-if="show" :class="tab == 'notice' ? 'active' : ''" @click="changeTab('notice')">消息中心</div>
        </div>
        <div class="help-right">
          <div class="help-list">
            <div class="help-total flex-a">
              <span class="line"></span>
              <span>共{{ total }}条记录</span>
            </div>
            <div class="help-item" v-for="item in list" :key="item.id" @click="checkDetail(item.id)">
              <div class="help-item-title" v-if="tab == 'help'">{{ item.name }}</div>
              <div class="help-item-title" v-if="tab == 'notice'">{{ item.content }}</div>
              <div class="help-item-content flex-a flex-b">
                <div v-if="tab == 'help'">{{ item.remark }}</div>
                <div class="help-item-time">{{ item.updateTime }}</div>
              </div>
            </div>
          </div>
          <div class="page-list" v-if="list.length > 0">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { getHelpInfoByToken, getHelpInfo, getMsgInfo } from './../lib/api/index'
export default {
  name: 'help',
  data() {
    return {
      total: 0,
      list: [],
      tab: 'help',
      show: false
    }
  },
  created() {
    if (window.localStorage.getItem("token")) {
      this.show = true
    } else {
      this.show = false
    }
    if (this.tab == 'help') {
      if (window.localStorage.getItem("token")) {
        this.getHelpInfoByToken({ pageNum: 1, pageSize: 10 })//帮助中心-登录
      } else {
        this.getHelpInfo({ pageNum: 1, pageSize: 10 })//帮助中心-未登录
      }
    } else if (this.tab == 'notice') {
      this.getMsgInfo({ pageNum: 1, pageSize: 10 })
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    //帮助中心-登录
    getHelpInfoByToken(params) {
      getHelpInfoByToken(params).then(res => {
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        }
      })
    },
    //帮助中心-未登录
    getHelpInfo(params) {
      getHelpInfo(params).then(res => {
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        }
      })
    },
    //消息中心
    getMsgInfo(params) {
      getMsgInfo(params).then(res => {
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        } else if (res.code == 401) {
          this.$alert('请先登录后再查看!', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              // this.$router.push('/')
            }
          });
        }
      })
    },
    // 查看详情
    checkDetail(id) {
      this.$router.push({ path: '/detail', query: { id: id } })
    },
    changeTab(txt) {
      this.tab = txt
      this.list = []
      if (txt == 'help') {
        if (window.localStorage.getItem("token")) {
          this.getHelpInfoByToken({ pageNum: 1, pageSize: 10 })//帮助中心-登录
        } else {
          this.getHelpInfo({ pageNum: 1, pageSize: 10 })//帮助中心-未登录
        }
      } else if (this.tab == 'notice') {
        this.getMsgInfo({ pageNum: 1, pageSize: 10 })
      }
    },
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      if (this.tab == 'help') {
        if (window.localStorage.getItem("token")) {
          this.getHelpInfoByToken({ pageNum: val, pageSize: 10 })//帮助中心-登录
        } else {
          this.getHelpInfo({ pageNum: val, pageSize: 10 })//帮助中心-未登录
        }
      } else if (this.tab == 'notice') {
        this.getMsgInfo({ pageNum: val, pageSize: 10 })
      }
    },
  },
  computed: {

  }
}
</script>

<style lang="less">
.help-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .help-body {
    padding: 20px 0;

    .containter {
      padding: 0;

      .help-left {
        background: #F5F6F8;
        width: 200px;
        min-height: 90vh;
        border-radius: 5px;

        div {
          text-align: center;
          height: 60px;
          line-height: 60px;
          color: #333;
        }

        div.active {
          background: #5478C5;
          color: #fff;
        }

        div:hover {
          cursor: pointer;
        }
      }

      .help-right {
        padding: 20px;
        width: calc(100% - 200px);
        box-sizing: border-box;

        .help-list {
          border: 1px solid #E1E7EF;
          border-top: none;

          .help-total {
            background: #F5F6F8;
            height: 40px;
            color: #666;
            font-size: 14px;

            .line {
              display: inline-block;
              width: 3px;
              height: 40px;
              background: #3577E7;
              margin-right: 5px;
            }
          }

          .help-item {
            height: 70px;
            padding: 15px 15px;
            box-sizing: border-box;

            .help-item-title {
              font-size: 14px;
              color: #333;
              margin-bottom: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }

            .help-item-content {
              color: #666;
              font-size: 12px;

              div:first-child {
                width: 85%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }

              .help-item-time {
                color: #999;
              }
            }
          }

          .help-item:not(:last-child) {
            border-bottom: 1px solid #F3F6F9;
          }

          .help-item:hover {
            cursor: pointer;
          }
        }

        .page-list {
          margin: 20px auto;
          text-align: center;
        }
      }
    }
  }
}
</style>
